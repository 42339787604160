<script>
// import UserGroupDesc from '../../components/UserGroupDesc.vue';

export default {
  name: 'UserTasksPage',

  // components: { UserGroupDesc },

  data() { return {
    targetUser: null,
  }},

  created() {
    this.$api.get({ path :`users/${this.$route.params.id}/profile` }, ({response}) => {
      const { user } = response.data;
      this.targetUser = user;
    });
  },

  methods: {
    OpenProfile()
    {
      window.location.href = `/user/${this.targetUser.id}/edit`;
    },
  }
}
</script>

<template>
  <loading-box v-if="!targetUser">

  </loading-box>
  <div v-else>
    <!-- USER PANEL -->
    <div class="user-task-panel">
      <div style="display: flex; align-items: center; flex-wrap: wrap;">
        <img :src="[targetUser.picture ? targetUser.picture: $utils.ProfilePic(targetUser.f_name, targetUser.s_name)]" />
        <h2>
          Task-uri <b style="color: #0077ff;">{{targetUser.f_name}} {{targetUser.s_name}}</b>
        </h2>
      </div>
      <div class="u-t-buttons">
        <button @click="$pop.openTaskForm(targetUser)" class="icon-button material-icons">add_task</button>
        <button @click="OpenProfile()" class="icon-button material-icons">person</button>
      </div>
    </div>

    <tasks-view :userID="$route.params.id" />
    <!-- <user-group-desc :groupId="targetUser.ugroup"></user-group-desc> -->
  </div>
</template>

<style scoped>

.user-task-panel {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  justify-content: space-between;
  transition-duration: 0.3s;
  width: 100%;
  background-color: var(--dark-box-color);
}

.user-task-panel img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: solid 1px var(--border-color);
  padding: 5px;
  float: left;
  object-fit: cover;
}

.user-task-panel h2 {
  margin-inline: 10px;
  float: left;
  line-height: 30px;
}


.u-t-buttons {
  justify-self: right;
  gap: 10px;
  display: flex;
}

.u-t-buttons button {
  padding: 10px;
  font-size: 25px;
}

@media only screen and (max-width: 500px) {
  .user-task-panel {
    width: 100%;
    text-align: center;
    flex-direction: column;
  }

  .user-task-panel img {
    margin: auto;
  }

  h1 {
    padding: 0px;
  }
}
</style>
